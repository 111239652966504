/*** Default ***/
@import "../utils/variables";

/*** Themes ***/
@import "../themes/Newman/theme";
@import "../themes/Newman/fonts";

/*** Mixins ***/
@import "../utils/mixins"; 

/*** Vendors ***/
@import "../utils/vendors";

/*** Base ***/
@import "../utils/base";

/*** Components ***/
@import "../utils/components";

/*** Layout ***/
@import "../themes/TARGETconnect/layout/custom";
@import "../themes/TARGETconnect/layout/job-posting";
@import "../themes/TARGETconnect/layout/unauthorised";
@import "../themes/TARGETconnect/layout/swipecard";

/*** Migration : Sherlock ****/
@import "../themes/default/layout/migration/sherlock/migration";

/*** Migration - Foundation : Romeo ****/
@import "../themes/default/layout/migration/romeo";

/*** Layout ***/
@import "../utils/layout";
